import React, { useState } from 'react';
import { Bug } from '../types';
import { bugData } from '../data/bugData';
import './RandomBugGenerator.css';  // Importing the CSS file

const RandomBugGenerator: React.FC = () => {
  const [randomBug, setRandomBug] = useState<Bug | null>(null);

  const generateRandomBug = () => {
    const bug: Bug = bugData[Math.floor(Math.random() * bugData.length)];
    setRandomBug(bug);
  };

  return (
    <div className="random-bug-generator">
      <h1>🔀 Random Bug Generator 🔀</h1>
      <button onClick={generateRandomBug}>Generate Random Bug 🎲</button>
      {randomBug && (
        <div>
          <h2>{randomBug.name}</h2>
          <p>{randomBug.description}</p>
          <img src={randomBug.imageUrl} alt={randomBug.name} />
        </div>
      )}
    </div>
  );
};

export default RandomBugGenerator;
import React from 'react';
import './HomePage.css';
import BugOfTheDay from './BugOfTheDay';
import BugGlossary from './BugGlossary';
import RandomBugGenerator from './RandomBugGenerator';
import Quiz from './Quiz';
import BugPartyCorner from './BugPartyCorner';

const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      <h1>🐛 Welcome to Bug Clan in Dota 2 🐛</h1>
      <p>Your ultimate resource for everything related to the 'Bug Clan' in Dota 2. Dive into the world of Dota 2's most interesting bugs, their abilities, and their roles in the game.</p>
      <div className="festive-section">
        <img src="/images/bug_clan.jpg" alt="Bug Clan" className="home-image" />
        <div className="festive-content">
          <h2>Discover the Clan 🕷️</h2>
          <p>Each bug in the clan has unique abilities and characteristics that make them formidable in battle. Learn more about your favorite bugs by browsing our extensive glossary.</p>
          <h2>Stay Updated 🐜</h2>
          <p>Don't miss out on the 'Bug of the Day' and use our Random Bug Generator to explore new bugs at any time!</p>
        </div>
      </div>
      <BugOfTheDay />
      <BugGlossary />
      <RandomBugGenerator />
      <Quiz />
      <BugPartyCorner />
      <div className="code-of-honour">
        <h2>Bug Clan Code of Honour 🦗</h2>
        <p>The Bug Clan holds a revered code of honour that all members must adhere to. First and foremost is the principle of Unity. Each member of the clan, regardless of their abilities or roles, must work together to ensure the prosperity and strength of the clan. No bug fights alone; the strength of the clan is the strength of its unity.</p>
        <p>Secondly, Respect is paramount. Every member of the Bug Clan must respect their allies and enemies alike. Understanding and acknowledging the strengths and weaknesses of others enables the clan to grow stronger. Disrespect is not tolerated and is swiftly dealt with to maintain harmony within the clan.</p>
        <p>Lastly, the pursuit of Knowledge is a continuous journey for all members. The Bug Clan believes that continuous learning and adaptation are essential for survival and dominance. Members are encouraged to share their experiences and insights to foster a culture of growth and improvement. By adhering to this code of honour, the Bug Clan ensures its enduring legacy in the world of Dota 2.</p>
      </div>
    </div>
  );
};

export default HomePage;
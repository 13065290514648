import React, { useState } from 'react';
import { Bug } from '../types';
import { bugData } from '../data/bugData';
import BugDetailedView from './BugDetailedView';
import './BugGlossary.css';  // Import the CSS file to apply styles

const BugGlossary: React.FC = () => {
  const [selectedBug, setSelectedBug] = useState<Bug | null>(null);

  return (
    <div className="bug-glossary">
      <h1>📖 Bug Glossary 📖</h1>
      <ul>
        {bugData.map((bug: Bug, index: number) => (
          <li key={index} onClick={() => setSelectedBug(bug)}>
            <h2>{bug.name}</h2>
            <img src={bug.imageUrl} alt={bug.name} />
          </li>
        ))}
      </ul>
      {selectedBug && <BugDetailedView bug={selectedBug} />}
    </div>
  );
};

export default BugGlossary;
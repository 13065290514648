import React, { useState } from 'react';
import './Quiz.css';

const Quiz: React.FC = () => {
  const questions = [
    {
      question: 'What is the essence of your being?',
      options: ['Shadow', 'Light', 'Balance', 'Chaos']
    },
    {
      question: 'If you could converse with any entity, who would it be?',
      options: ['An ancient tree', 'A silent predator', 'A guiding star', 'A lurking shadow']
    },
    {
      question: 'Which environment do you thrive in?',
      options: ['A dense forest', 'Desert sands', 'Dark caverns', 'Mystical ruins']
    },
    {
      question: 'How do you approach a challenge?',
      options: ['Strategically', 'Head-on', 'Subtly', 'Relentlessly']
    },
    {
      question: 'Where would you seek refuge?',
      options: ['In the heart of a forest', 'Amidst shifting sands', 'In the stillness of night', 'Among the whispers of the wind']
    },
    {
      question: 'Which trait do you value most?',
      options: ['Resilience', 'Adaptability', 'Stealth', 'Intellect']
    },
    {
      question: 'What is most important to you in a team?',
      options: ['Unity', 'Strength', 'Cunning', 'Knowledge']
    },
    {
      question: 'How do you handle conflict?',
      options: ['Diplomatically', 'With precision', 'With overwhelming force', 'By avoiding it']
    },
    {
      question: 'What motivates you?',
      options: ['Loyalty', 'Resourcefulness', 'Harmony', 'Power']
    },
    {
      question: 'What is your philosophy of life?',
      options: ['Live and let live', 'Survival of the fittest', 'Seek and you shall find', 'Balance in all things']
    }
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [result, setResult] = useState<string | null>(null);

  const handleOptionClick = (option: string) => {
    const nextQuestion = currentQuestion + 1;
    const updatedAnswers = [...userAnswers, option];
    setUserAnswers(updatedAnswers);

    if (nextQuestion < questions.length) { 
      setCurrentQuestion(nextQuestion);
    } else {
      const calculatedResult = calculateResult(updatedAnswers);
      setResult(calculatedResult);
    }
  };

  const calculateResult = (answers: string[]): string => {
    const bugScores: { [key: string]: number } = {};

    const answerMappings: { [key: string]: string[] } = {
      'Shadow': ['Nyxie'],
      'Light': ['Pugnacious'],
      'Balance': ['Weaveryn'],
      'Chaos': ['Venoming'],
      'An ancient tree': ['Broodling'],
      'A silent predator': ['Nyxie'],
      'A guiding star': ['Weaveryn'],
      'A lurking shadow': ['Venoming'],
      'Wisdom': ['Pugnacious'],
      'Power': ['Sand Emperor'],
      'Freedom': ['Weaveryn'],
      'Order': ['Venoming'],
      'Strategically': ['Nyxie'],
      'Head-on': ['Sand Emperor'],
      'Subtly': ['Broodling'],
      'Relentlessly': ['Venoming'],
      'In the heart of a forest': ['Broodling'],
      'Amidst shifting sands': ['Sand Emperor'],
      'In the stillness of night': ['Nyxie'],
      'Among the whispers of the wind': ['Weaveryn'],
      'Resilience': ['Sand Emperor'],
      'Adaptability': ['Pugnacious'],
      'Stealth': ['Nyxie'],
      'Intellect': ['Venoming'],
      'Unity': ['Venoming'],
      'Strength': ['Sand Emperor'],
      'Cunning': ['Nyxie'],
      'Knowledge': ['Pugnacious'],
      'Diplomatically': ['Pugnacious'],
      'With precision': ['Nyxie'],
      'With overwhelming force': ['Sand Emperor'],
      'By avoiding it': ['Weaveryn'],
      'Loyalty': ['Venoming'],
      'Resourcefulness': ['Weaveryn'],
      'Harmony': ['Broodling'],
      'Live and let live': ['Venoming'],
      'Survival of the fittest': ['Sand Emperor'],
      'Seek and you shall find': ['Pugnacious'],
      'Balance in all things': ['Weaveryn']
    };

    answers.forEach(answer => {
      const bugs = answerMappings[answer];
      bugs?.forEach(bug => { 
        if (!bugScores[bug]) bugScores[bug] = 0;
        bugScores[bug]++;
      });
    });

    // Find the bug with the highest score
    let maxScore = -1;
    let determinedBug = '';
    for (const bug in bugScores) {
      if (bugScores[bug] > maxScore) { 
        maxScore = bugScores[bug];
        determinedBug = bug;
      }
    }

    return determinedBug || 'Unknown Bug';
  };

  if (result) {
    return (
      <div className='quiz-container'>
        <h1>You are a {result}!</h1>
        <button onClick={() => window.location.reload()}>Retake Quiz</button>
      </div>
    );
  }

  return (
    <div className='quiz-container'>
      <h1>✨ What Bug Are You? ✨</h1>
      <div className='question'>{questions[currentQuestion].question}</div>
      <ul className='options'>
        {questions[currentQuestion].options.map((option, index) => (
          <li key={index}>
            <button onClick={() => handleOptionClick(option)}>{option}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Quiz;

import React, { useEffect, useState } from 'react';
import { Bug } from '../types';
import { bugData } from '../data/bugData';
import './BugOfTheDay.css';

const BugOfTheDay: React.FC = () => {
  const [bugOfTheDay, setBugOfTheDay] = useState<Bug | null>(null);

  useEffect(() => {
    const storedBug = sessionStorage.getItem('bugOfTheDay');
    const storedDate = sessionStorage.getItem('bugOfTheDayDate');
    const today = new Date().toDateString();

    if (storedBug && storedDate === today) {
      setBugOfTheDay(JSON.parse(storedBug));
    } else {
      const bug: Bug = bugData[Math.floor(Math.random() * bugData.length)];
      sessionStorage.setItem('bugOfTheDay', JSON.stringify(bug));
      sessionStorage.setItem('bugOfTheDayDate', today);
      setBugOfTheDay(bug);
    }
  }, []);

  return (
    <div className="bug-of-the-day">
      <h1>🐞 Bug of the Day 🐞</h1>
      {bugOfTheDay ? (
        <>
          <img src={bugOfTheDay.imageUrl} alt={bugOfTheDay.name} />
          <h2>{bugOfTheDay.name}</h2>
          <p>{bugOfTheDay.description}</p>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BugOfTheDay;
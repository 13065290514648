import React from 'react';
import { bugData } from '../data/bugData';
import './BugPartyCorner.css';

const BugPartyCorner: React.FC = () => {
  const randomTexts = ['Bug Bash!', 'Spin Party!', 'Creepy Crawlies!'];
  const randomText = randomTexts[Math.floor(Math.random() * randomTexts.length)];
  const randomBugs = bugData.sort(() => 0.5 - Math.random()).slice(0, 5); // Get 5 random bugs

  return (
    <div className="bug-party-container">
      <h1 className="party-text">{randomText}</h1>
      <div className="bug-images">
        {randomBugs.map((bug, index) => (
          <img key={index} src={bug.imageUrl} alt={bug.name} className="bug-image" />
        ))}
      </div>
    </div>
  );
};

export default BugPartyCorner;
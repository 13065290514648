import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar: React.FC = () => {
  const toggleDarkMode = () => {
    document.body.classList.toggle('dark-mode');
  };

  return (
    <nav className='navbar'>
      <ul className='navbar-links'>
        <li><Link to='/'>Home 🌟</Link></li>
        <li><a href='#bug-of-the-day'>Bug of the Day 🐞</a></li>
        <li><a href='#bug-glossary'>Bug Glossary 📖</a></li>
        <li><a href='#random-bug-generator'>Random Bug Generator 🎲</a></li>
        <li><a href='#quiz'>What Bug Are You Quiz ❓</a></li>
        <li><a href='#bug-party'>Bug Party Corner 🎉</a></li>
        <li><button onClick={toggleDarkMode} className='dark-mode-toggle'>Toggle Dark Mode 🌙</button></li>
      </ul>
    </nav>
  );
};

export default Navbar;
import { Bug } from '../types';

export const bugData: Bug[] = [
  {
    name: 'Weaveryn',
    description: 'A master of time manipulation and sneaky tactics.',
    imageUrl: '/images/weaver.svg',
    abilities: ['Shukuchi', 'Geminate Attack', 'The Swarm', 'Time Lapse'],
    roles: ['Carry', 'Escape']
  },
  {
    name: 'Nyxie',
    description: 'A deadly assassin with unparalleled stealth capabilities.',
    imageUrl: '/images/nyx.svg',
    abilities: ['Impale', 'Mana Burn', 'Spiked Carapace', 'Vendetta'],
    roles: ['Nuker', 'Initiator']
  },
  {
    name: 'Venoming',
    description: 'A vicious ally that spews venomous liquids at enemies.',
    imageUrl: '/images/venomancer.svg',
    abilities: ['Venomous Gale', 'Poison Sting', 'Plague Ward', 'Poison Nova'],
    roles: ['Support', 'Nuker']
  },
  {
    name: 'Broodling',
    description: 'A fearsome brood that births venomous spiderlings.',
    imageUrl: '/images/broodmother.svg',
    abilities: ['Spawn Spiderlings', 'Spin Web', 'Incapacitating Bite', 'Insatiable Hunger'],
    roles: ['Carry', 'Pusher']
  },
  {
    name: 'Sand Emperor',
    description: 'Master of the desert sands causing powerful earthquakes.',
    imageUrl: '/images/sand_king.svg',
    abilities: ['Burrowstrike', 'Sand Storm', 'Caustic Finale', 'Epicenter'],
    roles: ['Initiator', 'Support']
  },
  {
    name: 'Pugnacious',
    description: 'A necromancer that drains life from his foes.',
    imageUrl: '/images/pugna.svg',
    abilities: ['Nether Blast', 'Decrepify', 'Nether Ward', 'Life Drain'],
    roles: ['Nuker', 'Support']
  }
];
import React from 'react';
import { Bug } from '../types';
import './BugDetailedView.css';

interface BugDetailedViewProps {
  bug: Bug;
}

const BugDetailedView: React.FC<BugDetailedViewProps> = ({ bug }) => {
  return (
    <div className="bug-detailed-view">
      <h2>{bug.name}</h2>
      <img src={bug.imageUrl} alt={bug.name} />
      <p>{bug.description}</p>
      <h3>Abilities</h3>
      <ul>
        {bug.abilities?.map((ability, index) => (
          <li key={index}>{ability}</li>
        ))}
      </ul>
      <h3>Roles</h3>
      <ul>
        {bug.roles?.map((role, index) => (
          <li key={index}>{role}</li>
        ))}
      </ul>
    </div>
  );
};

export default BugDetailedView;